/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
//
import React from 'react';
import { withRouter } from 'react-router';
import { UIDReset, UIDConsumer } from 'react-uid';
import { object, string, array } from 'prop-types';
import { compose } from 'recompose';

import HomePageBlock from './HomePageBlock';
import UnderNavTextWidgetContainer from '../GraphqlCommonComponents/GraphqlUnderNavTextWidget/GraphqlUnderNavTextWidgetContainer';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import SeoHomepageJsonLdSchema from './Partials/SeoHomepageJsonLdSchema/SeoHomepageJsonLdSchema';

import { coreBrowsingPageViewSuccessTest } from '../../../helpers/tracking/common/commonTrackingHelpers';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import RenderWhenVisible from '../../../helpers/componentVisibility/RenderWhenVisible';

const FOLD_INDEX = 4;
const GraphqlHomePageBody = ({
    brand,
    blocks,
    seo,
    page,
    domainURL,
    ssrDeviceType,
    presentationFamily,
}) => (
    <>
        <SeoHomepageJsonLdSchema brand={brand} domainURL={domainURL} />
        <GraphqlSeoHeadTags seoData={seo} alternativeLang="true" />
        <UnderNavTextWidgetContainer page={page} presentationFamily={presentationFamily} />
        {
            blocks
                ? (
                    <UIDReset>
                        <UIDConsumer>
                            {(id, uid) => (
                                blocks.map((block, index) => {
                                    if (index <= FOLD_INDEX) {
                                        return (
                                            <HomePageBlock
                                                key={uid(block)}
                                                block={block}
                                                ssrDeviceType={ssrDeviceType}
                                                presentationFamily={presentationFamily}
                                                brand={brand}
                                            />
                                        );
                                    }

                                    return (
                                        <RenderWhenVisible>
                                            <HomePageBlock
                                                key={uid(block)}
                                                block={block}
                                                ssrDeviceType={ssrDeviceType}
                                                presentationFamily={presentationFamily}
                                                brand={brand}
                                            />
                                        </RenderWhenVisible>
                                    );
                                })
                            )}
                        </UIDConsumer>
                    </UIDReset>
                )
                : null
        }
    </>
);

GraphqlHomePageBody.propTypes = {
    brand: object.isRequired,
    ssrDeviceType: string.isRequired,
    blocks: array,
    seo: object,
    domainURL: string,
    page: object.isRequired,
    presentationFamily: string.isRequired,
};

GraphqlHomePageBody.defaultProps = {
    blocks: [],
    seo: {},
    domainURL: '',
};

const enhance = compose(
    withRouter,
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
    }),
);

export default enhance(GraphqlHomePageBody);
