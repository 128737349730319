/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 *    returns an array of strings ( 10 dates )
 */

export const AMPMToMilitary = (cutOffTime) => {
    // this function coverts standard US time into miliary time
    // eg. '1:30 PM' into 1330
    // all of the elvis operators are to ensure that if a cutOffTime of undefined is passed into
    // the function that the code does not crash.

    const timeAndAmOrPm = cutOffTime?.split(' '); // '2:30 PM' => ['2:30', 'PM'}
    const amOrPm = timeAndAmOrPm?.[1]; // 'PM'
    const time = timeAndAmOrPm?.[0]; // '2:30'
    const hoursMinutes = time?.split(':'); // '2:30' => ['2', '30']
    let hours = hoursMinutes?.[0]; // '2'
    const minutes = hoursMinutes?.[1]; // '30'
    if (amOrPm === 'PM') {
        hours = Number(hours); // '2' => 2
        hours += 12; // 2 => 14
    }
    const convertedTime = Number(`${hours}${minutes}`); // 14 '30' => 1430
    return convertedTime;
};

const getNextNDates = (numberOfDatesToShow = 10, cutOffTimes) => {
    const date = new Date(); // date obj
    const hour = date.getHours(); // current hour in military time
    const minutes =    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes(); // current minute in military time add zero if minutes < 10 it will only display 3 digits i.e. if current time = 11:04 AM will only show 114
    const currentTime = Number.parseFloat(`${hour}${''}${minutes}`); // convert to number so we can compare numbers ( currenTime )
    const day = date.getDay(); // get the day of the week for cutoff times
    const datesArray = []; // data structure we store our array of strings ( 10 dates )

    // change cutoff time depending on day of the week
    let cutOffTime = AMPMToMilitary(cutOffTimes?.[0]?.cutoff_time) || 1430; // 2:30PM default cutoff time for monday-friday
    if (day === 6) {
    // if saturday
        cutOffTime = AMPMToMilitary(cutOffTimes?.[1]?.cutoff_time) || 1300; // 1:00PM
    } else if (day === 0) {
    // if sunday
        cutOffTime = AMPMToMilitary(cutOffTimes?.[2]?.cutoff_time) || 1130; // 11:30AM
    }
    // if our currentTime is passed our cutoff time add + 1 to get the tomorrows date - if not use todays date
    let nextDate = currentTime > cutOffTime ? date.getDate() + 1 : date.getDate();

    // for loop runs numberOfDatesToShow times
    for (let i = 0; i < numberOfDatesToShow; i += 1) {
    // if its our first date keep as is if its any other date add + 1 to get the following date
        if (i > 0) {
            nextDate = date.getDate() + 1;
        }
        // format
        date.setDate(nextDate);
        const newDate = date.toLocaleString('en-US', {
            weekday: 'long',
            month: 'short',
            day: 'numeric',
        });
        // add our formatted date to our array
        datesArray.push(newDate);
    }
    return datesArray;
};

export default getNextNDates;
