/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, string } from 'prop-types';
import { Helmet } from 'react-helmet';

const SeoHPJsonLdSchema = ({ brand, domainURL }) => (
    <Helmet>
        (
        <script type="application/ld+json">
            {`{"@context": "http://schema.org","@type": "WebSite","url": "${domainURL}","potentialAction": {"@type": "SearchAction","target": "${domainURL}/webapp/wcs/stores/servlet/Search?catalogId=13302&brandIdTab=18F_&storeId=20051&langId=-1&pageSize=12&currentPage=1&beginIndex=0&sType=SimpleSearch&resultCatEntryType=2&ip_navtype=search&searchTerm={search_term_string}","query-input": "required name=search_term_string"}}`}
        </script>
        <script type="application/ld+json">
            {`{"@context": "https://schema.org","@type": "Organization","name": "${brand.description}","logo": "https://cdn1.1800flowers.com/wcsstore/RAPIDStorefrontAssetStore/images/flowers.png","url": "${domainURL}","parentOrganization": "1-800-FLOWERS.COM, INC","address": {"@type": "PostalAddress","addressLocality": "Carle Place","addressRegion": "NY","postalCode": "11514","streetAddress": "1 Old Country Road, Suit 500"},"contactPoint": [{"@type": "ContactPoint","telephone": "+1-800-716-4851","contactType": "Customer Service","sameAs": ["https://www.facebook.com/${brand.domain}/","https://twitter.com/${brand.domain}","https://www.instagram.com/${brand.domain}/","https://www.youtube.com/${brand.domain}","https://www.linkedin.com/company/${brand.domain}-com","https://www.pinterest.com/${brand.domain}/"]}]}`}
        </script>
        )
    </Helmet>
);

SeoHPJsonLdSchema.propTypes = {
    brand: object.isRequired,
    domainURL: string,
};

SeoHPJsonLdSchema.defaultProps = {
    domainURL: '',
};

export default SeoHPJsonLdSchema;
