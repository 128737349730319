/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import mbpLogger from 'mbp-logger';
import { object, string } from 'prop-types';
import { compose } from 'recompose';
import loadable from '@loadable/component';
import { GRAPHQL_ENV } from '../../../gql';
import GraphqlHomePageBody from './GraphqlHomePageBody';
import { getPresentationFamily, getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';
import { getCountryName, setLocale } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';

const HomePageSkeleton = loadable(() => import(/* webpackChunkName: "HomePageSkeleton" */ '../GraphqlSkeletonComponents/HomePageSkeleton'));
const determineCountry = () => {
    const countryPath = getCountryName();
    return countryPath;
};

const GraphqlHomePage = ({
    brand, page, ssrDeviceType, presentationFamily, featureFlags,
}) => {
    let isTest = false;
    if (page?.path?.includes('-test') && GRAPHQL_ENV !== 'production') {
        isTest = true;
    }

    // tablet needs desktop homepage
    let viewport = ssrDeviceType === 'tablet' ? 'desktop' : ssrDeviceType;
    viewport = isTest ? `${viewport}Test` : viewport;
    const fetchPolicy = brand?.code === 'PLA' ? featureFlags['which-fetch-policy-variant'] : '';
    const HOMEPAGE_QUERY = gql`
            query HomePageQuery {
                findHomePage(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "${setLocale()}", url: "${determineCountry()}" viewport:  "${viewport}") {
                    content
                    showUnderNavText
                }
            }
        `;
    return (
        <Query fetchPolicy={fetchPolicy} query={HOMEPAGE_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return <HomePageSkeleton />; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        query: HOMEPAGE_QUERY,
                        component: 'GraphqlHomePage.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });

                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        query: HOMEPAGE_QUERY,
                        component: 'GraphqlHomePage.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });

                    return null;
                }
                if (!data.findHomePage.content) {
                    mbpLogger.logWarning({
                        query: HOMEPAGE_QUERY,
                        component: 'GraphqlHomePage.js',
                        message: 'No content returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                if (data) {
                    const homeBlocks = data?.findHomePage?.content?.entries?.[0]?.home_blocks || null;
                    const SEO = data?.findHomePage?.content?.entries?.[0]?.seo || {};
                    const domainURL = `https://www.${brand.domain}.com` || '';

                    mbpLogger.logInfo({
                        message: 'data returned from HomePageQuery',
                    });

                    return (
                        <GraphqlHomePageBody
                            brand={brand}
                            page={page}
                            blocks={homeBlocks}
                            seo={SEO}
                            domainURL={domainURL}
                            ssrDeviceType={ssrDeviceType}
                            presentationFamily={presentationFamily}
                            featureFlags={featureFlags}
                        />
                    );
                }

                return <>No Homepage</>;
            }}
        </Query>
    );
};

GraphqlHomePage.propTypes = {
    brand: object.isRequired,
    page: object.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    featureFlags: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    ssrDeviceType: getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
    featureFlags: getFeatureFlags(state),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
);

export default enhance(GraphqlHomePage);
