/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React from 'react';
import { object, shape, string } from 'prop-types';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';
import { Container } from '@material-ui/core';

// components
// eslint-disable-next-line import/no-cycle
import ConnectedHomepageABTesting from '../AB_Testing/hocs/HomepageABTesting';

// helpers
import getNextNDates from '../../../helpers/getNextNDates';
import isEmptyReference from '../../../helpers/contentstack/isEmptyReference';
import { getLanguageName } from '../../../../state/ducks/Member/ducks/Auth/helper/helper';

// components
const GraphqlMobileCategory = loadable(() => import(/* webpackChunkName: "GraphqlMobileCategory" */ './Partials/GraphqlMobileCategory/GraphqlMobileCategory'));
const ConnectedGraphqlFindGiftFast = loadable(() => import(/* webpackChunkName: "ConnectedGraphqlFindGiftFast" */ './Partials/GraphqlFindAGiftFast/GraphqlFindAGiftFast'));
const ConnectedGraphqlFoodFindPerfect = loadable(() => import(/* webpackChunkName: "ConnectedGraphqlFoodFindPerfect" */ './Partials/GraphqlFindAGiftFast/GraphqlFoodGiftFinder'));
const GraphqlHPTiers = loadable(() => import(/* webpackChunkName: "GraphqlHPTiers" */ './Partials/GraphqlHomepageTiers/GraphqlHPTiers'));
const HPSilos = loadable(() => import(/* webpackChunkName: "HPSilos" */ './Partials/GraphqlHPSilos/GraphqlHPSilos'));
const GraphqlSeoFooter = loadable(() => import(/* webpackChunkName: "GraphqlSeoFooter" */ './Partials/GraphqlSeoFooter/GraphqlSeoFooter'));
const TrendingProductsBuilder = loadable(() => import(/* webpackChunkName: "TrendingProductsBuilder" */ '../GraphqlCommonComponents/TrendingProducts/TrendingProductsBuilder'));
const GraphqlHPCategoryCarousel = loadable(() => import(/* webpackChunkName: "GraphqlHPCategoryCarousel" */ './Partials/GraphqlHPCategoryCarousel/GraphqlHPCategoryCarousel'));
const ShopByFilter = loadable(() => import(/* webpackChunkName: "ShopByFilter" */ './Partials/ShopByFilters/ShopByFilters'));
const PlantsForOccasion = loadable(() => import(/* webpackChunkName: "PlantsForOccasions" */ './Partials/GraphqlHPSilos/Partials/PlantsForOccasions/PlantsForOccasions'));
const PlantsTrendingProductsBuilder = loadable(() => import(/* webpackChunkName: "PlantsTrendingProductsBuilder" */ '../GraphqlCommonComponents/TrendingProducts/PlantsTrendingProductsBuilder'));
const ProductRecentlyViewed = loadable(() => import(/* webpackChunkName: "ProductRecentlyViewed" */ '../GraphqlCommonComponents/ProductRecentlyViewed'));
const ConnectedHeroImage = loadable(() => import(/* webpackChunkName: "ConnectedHeroImage" */ './Partials/HeroImage/HeroImage'));

// ContentTypeComponents
const HeroSingle = loadable(() => import(/* webpackChunkName: "HeroSingle" */ '../../ContentTypeComponents/HeroSingle/HeroSingle'));
const FAGF = loadable(() => import(/* webpackChunkName: "HeroSingle" */ '../../ContentTypeComponents/FAGF/FAGF'));
const ThirdsHero = loadable(() => import(/* webpackChunkName: "ThirdsHero" */ '../../ContentTypeComponents/HeroThreeSection/HeroThreeSection'));
const InteractiveHero = loadable(() => import(/* webpackChunkName: "InteractiveHero" */ '../../ContentTypeComponents/HeroInteractive/HeroInteractive'));
const BannerGeneric = loadable(() => import(/* webpackChunkName: "BannerGeneric" */ '../../ContentTypeComponents/GenericBanner/GenericBanner'));
const ThreeUpFeature = loadable(() => import(/* webpackChunkName: "ThreeUpFeature" */ '../../ContentTypeComponents/ThreeUp/ThreeUp'));
const NUpFeature = loadable(() => import(/* webpackChunkName: "NUpFeature" */ '../../ContentTypeComponents/NUp/NUp'));
const NUpSimple = loadable(() => import(/* webpackChunkName: "NUpSimple" */ '../../ContentTypeComponents/NupSimple/NupSimple'));
// const SmsSignup = loadable(() => import(/* webpackChunkName: "SmsSignup" */ '../../ContentTypeComponents/SMSSignup/SMSSignup'));
// const WyngModule = loadable(() => import(/* webpackChunkName: "WyngModule" */ '../../ContentTypeComponents/Wyng/Wyng'));
// const Subcollection = loadable(() => import(/* webpackChunkName: "Subcollection" */ '../../ContentTypeComponents/SubCollection/SubCollection'));
// const UserGeneratedContent = loadable(() => import(/* webpackChunkName: "UserGeneratedContent" */ '../../ContentTypeComponents/UserGeneratedContent'));
const SeoBlock = loadable(() => import(/* webpackChunkName: "SeoBlock" */ '../../ContentTypeComponents/SeoBlock/SeoBlock'));
// const UserReviews = loadable(() => import(/* webpackChunkName: "UserReviews" */ '../../ContentTypeComponents/UserReviews'));
// const VideoModule = loadable(() => import(/* webpackChunkName: "VideoModule" */ '../../ContentTypeComponents/Video/Video'));
const LinkList = loadable(() => import(/* webpackChunkName: "LinkList" */ '../../ContentTypeComponents/LinkList/LinkList'));
const CountryFinder = loadable(() => import(/* webpackChunkName: "CountryFinder" */ '../../ContentTypeComponents/CountryFinder/CountryFinder'));
const PlantsYouLove = loadable(() => import(/* webpackChunkName: "PlantsYouLove" */ './Partials/Yotpo/PlantsYouLove'));
const PlantsYotpoReviewCarousel = loadable(() => import(/* webpackChunkName: "YotpoReviewCarousel" */ './Partials/Yotpo/YotpoReviewCarousel'));

// Banner UIs
// const ZoneTenUI = loadable(() => import(/* webpackChunkName: "ZoneTenUI" */ './Partials/Banners/ZoneTenUI'));
// const PassportBannerUI = loadable(() => import(/* webpackChunkName: "PassportBannerUI" */ './Partials/Banners/PassportBannerUI'));
// const CeoBannerUI = loadable(() => import(/* webpackChunkName: "CeoBannerUI" */ './Partials/Banners/CeoBannerUI'));
const ValuePropositionBanner = loadable(() => import(/* webpackChunkName: "ValuePropositionBanner" */ './Partials/Banners/ValuePropositionBanner'));

const HomePageBlock = ({
    block, ssrDeviceType, presentationFamily, brand,
}) => {
    if (!block) {
        mbpLogger.logWarning({
            message: 'No block types have been passed into HomePageBlock.js',
        });
        return null;
    }
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];
    const language = getLanguageName();

    // Run a check to see that a block's references are deployed to the same environment
    if (isEmptyReference(blockObj, blockKey, 'HomePageBlock.js')) return null;

    switch (blockKey) {
        // mobile only
        // case 'subcollection':
        //     return <GraphqlMobileSubCollection data={blockObj} ssrDeviceType={ssrDeviceType} />;
        // mobile only
        case 'mobile_tiles':
            return <GraphqlMobileCategory data={blockObj} presentationFamily={presentationFamily} />;

        case 'gift_finder': {
            const dateInfo = blockObj?.gift_finder_reference?.[0]?.gift_finder_blocks?.find((gfBlock) => gfBlock.delivery_date);
            const numDatesToDisplay = dateInfo?.delivery_date?.number_of_dates_to_show || 10;
            const cutOffTimes = dateInfo?.delivery_date?.today_cut_off_time;
            const dateOptions = getNextNDates(numDatesToDisplay, cutOffTimes);

            return <ConnectedGraphqlFindGiftFast dateInfo={dateInfo} data={blockObj} dateOptions={dateOptions} />;
        }
        case 'menu_link_group': {
            const blockData = blockObj.reference[0].menu_blocks;
            return typeof window !== 'undefined' && <ShopByFilter data={blockData} />;
        }
        case 'recently_viewed_products': {
            return (
                typeof window !== 'undefined' && (
                    <div style={{ backgroundColor: 'rgb(242, 242, 242)', marginBottom: '48px' }}>
                        <Container id="home-page-featured-container" className="plants-featured-container">
                            <ProductRecentlyViewed product={{}} />
                        </Container>
                    </div>
                )
            );
        }
        // mobile only
        case 'category_carousels':
            return typeof window !== 'undefined' && <GraphqlHPCategoryCarousel data={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} brand={brand} />;

        case 'gift_finder_gfb':
            return <ConnectedGraphqlFoodFindPerfect data={blockObj} />;

        case 'hero':
        case 'hero_basic':
            return <ConnectedHeroImage data={blockObj} ssrDeviceType={ssrDeviceType} brand={brand} language={language} />;

        case 'hero_single':
            return <HeroSingle data={blockObj} />;

        case 'hero_three_section':
            return <ThirdsHero data={blockObj} />;

        case 'hero_interactive':
            return <InteractiveHero data={blockObj} />;

        case 'generic_banner':
            switch (blockObj?.reference?.[0]?.title) {
                case 'FAGF':
                    return <FAGF />;
                case 'YotpoHPWidget':
                    return <PlantsYouLove isCustomerReviewPage={false} />;
                case 'ValuePropositionBanner':
                    return <ValuePropositionBanner />;
                case 'YotpoReviewCarousel':
                    return typeof window !== 'undefined' && <PlantsYotpoReviewCarousel />;
                default:
                    return <BannerGeneric data={blockObj} />;
            }

        case '3_up':
            return <ThreeUpFeature data={blockObj} />;

        case 'n_up':
            return <NUpFeature data={blockObj} />;

        case 'n_up_simple':
            return <NUpSimple data={blockObj} />;

        case 'country_finder_widget':
            return <CountryFinder data={blockObj?.country_finder_ref?.[0]} ssrDeviceType={ssrDeviceType} />;

        case 'category_tiles':
            return <GraphqlHPTiers data={blockObj} />;

        case 'silos':
            if (blockObj.name === 'plants_for_occasions') {
                return <PlantsForOccasion data={blockObj} brand={brand} />;
            }
            return <HPSilos data={blockObj} brand={brand} />;

        case 'seo':
            return <GraphqlSeoFooter data={blockObj} />;

        case 'seo_copy': // GFB
            return <SeoBlock data={blockObj} />;

            // case 'zone_10_banner':
            //     return <ZoneTenUI data={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />;

            // case 'zone_10_banner_v2':
            //     return <ZoneTenUI data={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />;

            // case 'passport':
            //     return <PassportBannerUI data={blockObj} />;

            // case 'ugc':
            //     return <UserGeneratedContent data={blockObj} />;

            // case 'reviews':
            //     return typeof window !== 'undefined' && <UserReviews data={blockObj} />;

            // case 'ceo_banner':
            //     return <CeoBannerUI data={blockObj} />;

        case 'trending_products':
            return typeof window !== 'undefined' && (brand.code === 'PLA' ? <PlantsTrendingProductsBuilder presentationFamily={presentationFamily} brand={brand} blockData={blockObj} ssrDeviceType={ssrDeviceType} /> : <TrendingProductsBuilder blockData={blockObj} ssrDeviceType={ssrDeviceType} />);

            // case 'mobile_sms_signup':
            //     return <SmsSignup data={blockObj} />;

            // case 'wyng':
            //     return <WyngModule data={blockObj} />;

            // case 'subcollection':
            //     return <Subcollection data={blockObj} ssrDeviceType={ssrDeviceType} />;

            // case 'video_module':
            //     return typeof window !== 'undefined' && <VideoModule data={blockObj} />;

        case 'link_list_module_mobile':
            return typeof window !== 'undefined' && <LinkList data={blockObj} type="footermenu" />;

        // this will eventually feedback into Homepage block once a/b variant data has been retrieved
        case 'a_b_testing_block':
            return <ConnectedHomepageABTesting block={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />;

        default:
            mbpLogger.logWarning({
                message: `Unknown HomePage block type "${blockKey}" in HomePageBlock.js`,
            });
            return null;
    }
};

HomePageBlock.propTypes = {
    block: object.isRequired,
    brand: shape({
        code: string,
    }).isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
};

export default HomePageBlock;
