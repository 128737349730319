/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { string, shape, arrayOf } from 'prop-types';
import mbpLogger from 'mbp-logger';
// components
// eslint-disable-next-line import/no-cycle
import HomePageBlock from '../../GraphqlHomePage/HomePageBlock';
// queries
import findContent from '../../../../gql/queries/findContent';

const ContentComponentContainer = ({
    variant,
    block,
    brand,
    ssrDeviceType,
    presentationFamily,
}) => {
    // TODO fallback?
    // queries and contentTypes will drive the determination for content - as ab tests expand this will become more functional (cross over between different pages? e.g. UNT)
    const query = `{\\"a_b_testing.unique_id\\": \\"${block?.unique_id}\\", \\"a_b_testing.variant\\": \\"${variant}\\"}`;
    const contentType =  block.reference?.[0]?.['_content_type_uid'];
    const findContentOptions = {
        brand,
        variant,
        contentType,
        typename: '__typename',
        query,
    };
    const FIND_HERO_QUERY = findContent(findContentOptions);
    const { loading, error, data } = useQuery(FIND_HERO_QUERY);

    if (loading) {
        return null;
    }
    if (error) {
        mbpLogger.logError({
            jsError: error,
            message: `Failed to fetch content data for: content type ${contentType}, variant ${variant} query ${query} on brand ${brand}`,
        });
    }

    if (data) {
        // reform the contentBlock type structure and feedback into Homepage
        const newBlock = { [contentType]: data?.findContent?.content?.entries?.[0] };

        return (
            <HomePageBlock
                block={newBlock}
                ssrDeviceType={ssrDeviceType}
                presentationFamily={presentationFamily}
            />
        );
    }
    return null;
};

ContentComponentContainer.propTypes = {
    brand: string.isRequired,
    variant: string.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    block: shape({
        reference: arrayOf(shape({
            uid: string.isRequired,
            _content_type_uid: string.isRequired,
        }).isRequired),
    }).isRequired,
};

export default ContentComponentContainer;
